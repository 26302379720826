import Vue from 'vue'
import VueRouter from 'vue-router'
import TestRouter from '@/views/TestRouter.vue';
import TestRouter2 from '@/views/TestRouter2.vue';
import HomeView from '@/views/HomeView.vue'
import NotificationsView from '@/views/NotificationsView.vue';

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/test',
    name: 'TestRouter',
    component: TestRouter,
  },
  {
    path: '/test/test2',
    name: 'TestRouter2',
    component: TestRouter2,
  },
  {
    path: '/notifications',
    name: 'NotificationsView',
    component: NotificationsView,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router;