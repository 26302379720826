<template>
  <div class="app">
    <div>
      Test navigation
      <router-link
        :to="{name: 'TestRouter2'}"
        replace
      >
        Go down
      </router-link>
    </div>
    <div class="method-container">
      <h5>backArrow.setOnClickHandler</h5>
      <pre>
        <code>
          () => this.$router.push({
            name: 'HomeView',
            replace: true
          });
        </code>
      </pre>
    </div>
    <div class="method-container">
      <h5>backArrow.setVisible</h5>
      <button @click="setBackArrowVisible(true)">set true</button>
      <button @click="setBackArrowVisible(false)">set false</button>
      <pre>
        <code>
          {{isVisibleRes}}
        </code>
      </pre>
    </div>
    <div class="method-container">
      <h5>backArrow.setMode</h5>
      <button @click="setBackArrowMode(true)">set true</button>
      <button @click="setBackArrowMode(false)">set false</button>
      <pre>
        <code>
          {{setModeRes}}
        </code>
      </pre>
    </div>
    <div class="method-container">
      <h5>setTitle</h5>
      <pre>
        <code>
          'Test Router 1'
        </code>
      </pre>
    </div>
    <div class="method-container">
      <h5>setTabActiveHandler</h5>
      <pre>
        <code>
          tabActiveHandler(){
            this.$router.replace({
                name: 'HomeView'
              })
          }
        </code>
      </pre>
    </div>
  </div>
</template>

<script>
import {
  isWebViewBridgeAvailable,
  setTabActiveHandler,
  setTitle,
  backArrow,
} from '@orta/bridge';

export default {
  data() {
    return {
      isVisibleRes: null,
      setModeRes: null,
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ name: 'HomeView', replace: true });
    },
    tabActiveHandler(tabname){
      console.log('tabname', tabname)
      // if (tabname === 'Orta'){
      // }
      this.$router.replace({
        name: 'HomeView'
      })
    },
    async setBackArrowVisible(value) {
      try {
        backArrow.setVisible(value);
        this.isVisibleRes = 'success'
      } catch (error) {
        this.isVisibleRes = error;
      }
    },
    async setBackArrowMode(value) {
      try {
        backArrow.setMode(value);
        this.setModeRes = 'success'
      } catch (error) {
        this.setModeRes = error
      }
    },
  },
  mounted() {
    if (isWebViewBridgeAvailable()) {
      // onNativeEvent(({event}) => event === 'tappedNavigationBarBackButton' && this.$router.push({ name: 'HomeView', replace: true }));
      setTitle('Page 1')
      // backArrow.setVisible(true)
      // backArrow.setMode(true)
      backArrow.setOnClickHandler(this.goBack);
      setTabActiveHandler(this.tabActiveHandler.bind(this))
    }
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.method-container {
  background: lightgray;
  padding: 1rem;
  margin: .5rem .2rem;
  border-radius: .5rem;
}

pre {
  text-align: start;
  overflow: auto;
}

.header-title {
  font-weight: 500;

}

.bool-status {
  font-weight: 600;
  padding: .2rem;
}

.bridge {
  color: white;
  background: lightgreen;
}

.not-bridge {
  color: white;
  background: red;
}

h5 {
  margin: .3rem 0;
}

button {
  margin: .5rem;
}
</style>