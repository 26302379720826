<template>
  <div class="app">
    <h1>Notifications screen</h1>
    <div class="method-container">
      <h5>setHeaderMenuItemClickHandler</h5>
      <pre>
        <code>
          headerMenuItemClickHandler(id){
            if (id === 'menu'){
              console.log('menu clicked!')
            } else if (id === 'notifications'){
              console.log('notifications clicked!');
              this.$router.push({ name: 'NotificationsView', replace: true });
            } else {
              console.log('Неизвестный эллемент меню: ' + id);
            }
          },
        </code>
      </pre>
    </div>
  </div>
</template>

<script>
import {isWebViewBridgeAvailable, setTitle, setTabActiveHandler, backArrow } from '@orta/bridge';

export default {
  mounted() {
    if(isWebViewBridgeAvailable()) {
      setTitle('Notificatons');
      backArrow.setVisible(true)
      backArrow.setOnClickHandler(() => this.$router.push({ name: 'HomeView', replace: true }))
      setTabActiveHandler(this.tabActiveHandler.bind(this))
    }
  },
  methods: {
    tabActiveHandler(){
      this.$router.replace({
        name: 'HomeView'
      })
      // if (tabname === 'Orta'){
      // }
    },
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.method-container {
  background: lightgray;
  padding: 1rem;
  margin: .5rem .2rem;
  border-radius: .5rem;
}

pre {
  text-align: start;
  overflow: auto;
}

.header-title {
  font-weight: 500;

}

.bool-status {
  font-weight: 600;
  padding: .2rem;
}

.bridge {
  color: white;
  background: lightgreen;
}

.not-bridge {
  color: white;
  background: red;
}

h5 {
  margin: .3rem 0;
}

button {
  margin: .5rem;
}
</style>