<template>
  <div class="app">
    <div class="method-container">
      <h5>setTabActiveHandler</h5>
      <pre>
        <code>
          tabActiveHandler(){
            this.$router.replace({
              name: 'HomeView'
            })
          }
        </code>
      </pre>
    </div>
  </div>
</template>

<script>
import {
  setTitle,
  backArrow,
  setTabActiveHandler,
  isWebViewBridgeAvailable,
} from '@orta/bridge';

export default {
  methods: {
    goBack() {
      this.$router.push({ name: 'TestRouter', replace: true });
    },
    tabActiveHandler(){
      this.$router.replace({
        name: 'HomeView'
      })
      // if (tabname === 'Orta'){
      // }
    }
  },
  mounted() {
    if (isWebViewBridgeAvailable()) {
      // onNativeEvent(({event}) => event === 'tappedNavigationBarBackButton' && this.$router.push({ name: 'TestRouter', replace: true }));
      setTitle('Page 2')
      setTabActiveHandler(this.tabActiveHandler.bind(this))
      backArrow.setOnClickHandler(this.goBack);
    }
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.method-container {
  background: lightgray;
  padding: 1rem;
  margin: .5rem .2rem;
  border-radius: .5rem;
}

pre {
  text-align: start;
  overflow: auto;
}

.header-title {
  font-weight: 500;
}

.bool-status {
  font-weight: 600;
  padding: .2rem;
}

.bridge {
  color: white;
  background: lightgreen;
}

.not-bridge {
  color: white;
  background: red;
}

h5 {
  margin: .3rem 0;
}

button {
  margin: .5rem;
}
</style>