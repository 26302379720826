<template>
  <div id="app">
    <div class="center" v-if="!ok">
      Your browser not supported 🤡!
    </div>
    <div v-else>
      <h3>Home page</h3>
      <!-- 1. bridge is available? -->
      <div class="method-container">
        <h3>isWebViewBridgeAvailable</h3>
        <span class="header-title">status</span> <span class="bool-status" :class="{bridge: ok, 'not-bridge': !ok}">{{ ok }}</span>
        <p>Debug: {{ catched }}</p>
      </div>

      <!-- 2. Get me -->
      <div class="method-container">
        <h3>getMe</h3>
        <button @click="callGetMe">getMe</button>
        <p>result:</p>
        <pre>
          {{ getMeRes }}
        </pre>
      </div>

      <!-- 4. show back btn -->
      <div class="method-container">
        <h3>backArrow</h3>
        <h5>backArrow.setMode</h5>
        <button @click="callBackArrowSetMode(false)">false</button>
        <button @click="callBackArrowSetMode(true)">true</button>
        <p>result:</p>
        <pre>
          {{ backBtnModeRes }}
        </pre>
        <h5>Go to internal page</h5>
        <div>
          <router-link
            :to="{path: '/test'}"
          >
            Page 1
          </router-link>
        </div>
        <h5>Go to external link</h5>
        <p>disable custom arrow mode before going to external link</p>
        <pre>
          backArrow.setMode(false)
          <!-- navigate to google.com -->
          window.location.href = "https://www.google.com/"
        </pre>
        <button @click="openExternalLink('https://www.google.com/')">https://www.google.com/</button>
      </div>

      <!-- 5. set menu items -->
      <div class="method-container">
        <h3>headerMenuItems</h3>
        <h5>headerMenuItems.setItems</h5>
        <select v-model="menu1">
          <option>Notifications</option>
          <option>Menu</option>
          <option>Person</option>
          <option>Reload</option>
          <option>Settings</option>
          <option>Close</option>
        </select>
        <select v-model="menu2">
          <option>Notifications</option>
          <option>Menu</option>
          <option>Person</option>
          <option>Reload</option>
          <option>Settings</option>
          <option>Close</option>
        </select>
        <input v-model="badgeCount" placeholder="set badge count" />
        <pre>
          {{ menuItems }}
        </pre>
        <button @click="callHeaderMenuItemsSetItems">set</button>
        <h5>headerMenuItems.setClickHandler</h5>
        <pre>
          headerMenuItemClickHandler(id){
            if (id === 'menu'){
              alert('menu clicked')
            } else if (id === 'notifications'){
              this.$router.push({ name: 'NotificationsView', replace: true });
            } else {
              alert('Unknown menu item: ' + id);
            }
          },
        </pre>
      </div>

      <!-- 7. get qr -->
      <div class="method-container">
        <h3>getQR</h3>
        <button @click="callGetQr">Scan</button>
        <p>result:</p>
        <pre>
          {{ qrRes }}
        </pre>
      </div>

      <!-- 8. copyToClipboard -->
      <div class="method-container">
        <h3>copyToClipboard</h3>
        <input v-model="toClipboard" placeholder="type clipboard text" />
        <button @click="callCopyToClipboard(toClipboard)">ok</button>
        <p>result:</p>
        <pre>
          {{ clipboardRes }}
        </pre>
      </div>

      <!-- 9. share -->
      <div class="method-container">
        <h3>share</h3>
        <input v-model="shareStr" placeholder="typee share text" />
        <button @click="callShare(shareStr)">share</button>
        <p>result:</p>
        <pre>
          {{ shareRes }}
        </pre>
      </div>

      <!-- 10. shareFile -->
      <div class="method-container">
        <h3>shareFile</h3>
        <h5>Share .png image</h5>
        <button @click="callShareFile('image')">img.png</button>
        <h5>Share .docx file</h5>
        <button @click="callShareFile('doc')">Untitled document.docx</button>
        <p>result:</p>
        <pre>
          {{ shareFileRes }}
        </pre>
      </div>

      <!-- 11. Enable Notifications -->
      <div class="method-container">
        <h3>notifications</h3>
        <button @click="callNotifications('enable')">enable</button>
        <button @click="callNotifications('disable')">disable</button>
        <p>result:</p>
        <pre>
          {{ notificationsRes }}
        </pre>
      </div>

      <!-- 13. set storage -->
      <div class="method-container">
        <h3>storage</h3>
        <h5>storage.setItem</h5>
        <input v-model="setStorageKey" placeholder="setStorageKey">
        <input v-model="setStorageValue" placeholder="setStorageValue">
        <button @click="callStorageSetItem">set</button>
        <h5>storage.getItem</h5>
        <input v-model="getStorageKey" placeholder="setStorageKey">
        <button @click="callStorageGetItem">get</button>
        <h5>storage.clear</h5>
        <button @click="callStorageClear">clear</button>
        <p>result:</p>
        <pre>
          {{ storageRes }}
        </pre>
      </div>

      <!-- 14. screen capture -->
      <div class="method-container">
        <h3>screenCapture</h3>
        <button @click="screenCapture(true)">disable</button>
        <button @click="screenCapture(false)">enable</button>
        <p>result:</p>
        <pre>
          {{ screenCaptureRes }}
        </pre>
      </div>

      <!-- log out -->
      <div class="method-container">
        <h3>logOut</h3>
        <button @click="callLogOut()">log out</button>
        <p>result:</p>
        <pre>
          {{ logOutRes }}
        </pre>
      </div>

      <!-- log out -->
      <div class="method-container">
        <h3>close</h3>
        <button @click="callClose">close mini app</button>
        <p>result:</p>
        <pre>
          {{ closeRes }}
        </pre>
      </div>

      <!-- 3. set title -->
      <div class="method-container">
        <h3>setTitle</h3>
        <input v-model="title" placeholder="new app title" />
        <button @click="callSetTitle">Set title</button>
        <p>result:</p>
        <pre>
          {{ setTitleRes }}
        </pre>
      </div>

      <!-- TEST Deeplinks & Weblinks -->
      <div class="method-container">
        <h3>test deeplink</h3>
        <input v-model="deeplink" placeholder="link" />
        <button @click="openExternalLink(deeplink)">GO</button>
      </div>

      <!-- Open settings bridge method -->
      <div class="method-container">
        <h3>openSettings</h3>
        <button @click="callOpenSettings()">open settings</button>
        <pre>
          {{ openSettingsRes }}
        </pre>
      </div>

      <!-- Open URL bridge method -->
      <div class="method-container">
        <h3>openUrl</h3>
        <input v-model="openUrlValue" placeholder="url" />
        <button @click="callOpenUrl(openUrlValue)">open</button>
        <pre>
          {{ openUrlRes }}
        </pre>
      </div>

      <!-- iframe youtube video -->
      <div class="method-container">
        <h3>iframe youtube video</h3>
        <div class="iframe-container">
          <iframe src="https://www.youtube.com/embed/fo6T5BwxFh0?si=ppFEJshtnY1jhbwo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>


      <!-- 18. nfc -->
      <div class="method-container">
        <h3>nfc</h3>
        <h5>nfc.isSupported</h5>
        <button @click="callNfcIsSupported">isSupported</button>
        <h5>nfc.isEnabled</h5>
        <button @click="callNfcIsEnabled">isEnabled</button>
        <h5>nfc.readTag</h5>
        <button @click="callNfcReadTag" :disabled="nfcIsReading">readTag</button>
        <h5>nfc.stopReading</h5>
        <button @click="callNfcStopReading">stopReading</button>
        <h5>nfc.openSettings</h5>
        <button @click="callNfcOpenSettings">openSettings</button>
        <p>result:</p>
        <pre>
          {{ nfcRes }}
        </pre>
      </div>

      <!-- 19. biometricAccess -->
      <div class="method-container">
        <h3>biometricAccess</h3>
        <h5>biometricAccess.authenticate</h5>
        <button @click="callBiometricAccessAuthenticate" :disabled="biometricAccessAuthenticating">authenticate</button>
        <p>result:</p>
        <pre>
          {{ biometricAccessAuthenticateResponse }}
        </pre>
      </div>

      <!-- 20. mobile topo open in newApp -->
      <div class="method-container">
        <h3>test open app by deeplink</h3>
        <a href="qollab://command=apps&amp;id=fcf27dfd-defa-4331-b0f0-e1b6bb5c6d01&amp;to_url=/"><div><p> GO </p></div></a>
      </div>

      <!-- 21. Get keycloak token -->
      <div class="method-container">
        <h3>getKeycloakToken</h3>
        <h5>Get Fresh Keycloak Token</h5>
        <button @click="callGetKeycloakToken">getKeycloakToken()</button>
        <h5>Get Cached Keycloak Token From Mobile Local Storage</h5>
        <button @click="callGetFreshKeycloakToken">getKeycloakToken({isFreshTokenNeeded: true})</button>
        <button @click="clearGetKeycloakToken">clearResult</button>
        <p>result:</p>
        <pre>
          {{ getKeycloakTokenRes }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isWebViewBridgeAvailable, // check is running into orta app
  headerMenuItems, // set menu items, click handler
  getMe, // get info about orta user
  getKeycloakToken, // get user's keycloak token
  getQr, // open camera to scan qr code and get scan result as string
  screenCapture, // enable or disable screen capture
  notifications, // enable or disable notifications
  copyToClipboard,
  setTabActiveHandler,
  share,
  shareFile,
  storage,
  logOut,
  setTitle,
  backArrow,
  close,
  openSettings,
  openUrl,
  nfc,
  biometricAccess
} from '@orta/bridge';
import {PNGIMAGE, DOC} from '../ShareImg'

export default {
  data() {
    return {
      catched: '',
      getMeRes: null,
      getKeycloakTokenRes: null,
      closeRes: null,
      setTitleRes: null,
      backBtnModeRes: null,
      headerMenuItemsRes: null,
      title: 'Orta bridge demo',
      deeplink: '',
      toClipboard: '',
      qrRes: null,
      clipboardRes: null,
      shareStr: '',
      shareRes: null,
      shareFileRes: null,
      notificationsRes: null,
      setStorageKey: '',
      setStorageValue: '',
      getStorageKey: '',
      storageRes: null,
      nfcRes: null,
      biometricAccessAuthenticateResponse: null,
      nfcIsReading: false,
      biometricAccessAuthenticating: false,
      screenCaptureRes: null,
      logOutRes: null,
      menu1: null,
      menu2: null,
      badgeCount: '3',
      openUrlValue: '',
      openUrlRes: null,
      openSettingsRes: null,
    }
  },
  computed: {
    ok() {
      return isWebViewBridgeAvailable()
    },
    menuItems() {
      return [
        {
          id:  "notifications",
          icon: this.menu1,
          badge: this.badgeCount,
        },
        {
          id: "menu",
          icon: this.menu2,
        },
        {
          id: "menu",
          icon: 'Person',
        },
      ]
    }
  },
  methods: {
    callGetMe() {
      getMe()
        .then((res) => this.getMeRes = res)
        .catch((e) => this.getMeRes = e)
    },
    callGetKeycloakToken() {
      getKeycloakToken()
        .then((res) => this.getKeycloakTokenRes = res)
        .catch((e) => this.getKeycloakTokenRes = e)
    },
    callGetFreshKeycloakToken() {
      getKeycloakToken({isFreshTokenNeeded: true})
        .then((res) => this.getKeycloakTokenRes = res)
        .catch((e) => this.getKeycloakTokenRes = e)
    },
    clearGetKeycloakToken() {
       this.getKeycloakTokenRes = null
    },
    callBackArrowSetMode(mode) {
      backArrow.setMode(mode)
        .then((res) => this.backBtnModeRes = res)
        .catch((e) => this.backBtnModeRes = e)
    },
    tabActiveHandler() {
      this.$router.replace({
        name: 'HomeView'
      })
    },
    callGetQr() {
      getQr()
        .then((res) => this.qrRes = res)
        .catch((e) => this.qrRes = e)
    },
    callCopyToClipboard(e) {
      copyToClipboard(e)
        .then((res) => this.clipboardRes = res)
        .catch((e) => this.clipboardRes = e)
    },
    callShare(e) {
      share(e)
        .then((res) => this.shareRes = res)
        .catch((e) => this.shareRes = e)
    },
    callShareFile(type) {
      if (type === 'image') {
        shareFile('example image', 'img.png', PNGIMAGE)
          .then((res) => this.shareFileRes = res)
          .catch((e) => this.shareFileRes = e);
      } else if (type === 'doc') {
        shareFile('example document', 'Untitled document.docx', DOC)
          .then((res) => this.shareFileRes = res)
          .catch((e) => this.shareFileRes = e);
      }
    },
    callNotifications(event) {
      if (event === 'enable') {
        notifications.enable()
          .then((res) => this.notificationsRes = res)
          .catch(e => this.notificationsRes = e)
      } else {
        notifications.disable()
          .then((res) => this.notificationsRes = res)
          .catch(e => this.notificationsRes = e)
      }
    },
    callSetTitle() {
      setTitle(this.title)
        .then((res) => this.setTitleRes = res)
        .catch((e) => this.setTitleRes = e);
    },
    async openExternalLink(link) {
      await backArrow.setMode(false)
      window.location.href = link
    },
    async callOpenSettings() {
      try {
        const res = await openSettings()
        this.openSettingsRes = res
      } catch (error) {
        this.openSettingsRes = error
      }
    },
    async callOpenUrl(url) {
      try {
        const res = await openUrl(url)
        this.openUrlRes = res;
      } catch (error) {
        this.openUrlRes = error;
      }
    },
    headerMenuItemClickHandler(id){
      if (id === 'menu'){
        alert('menu clicked')
      } else if (id === 'notifications'){
        this.$router.push({ name: 'NotificationsView', replace: true });
      } else {
        alert('Unknown menu item: ' + id);
      }
    },
    async callStorageSetItem() {
      try {
        const res = await storage.setItem(this.setStorageKey, this.setStorageValue);
        this.storageRes = res;
      } catch (error) {
        this.storageRes = error;
      }
    },
    callStorageGetItem() {
      storage.getItem(this.getStorageKey)
        .then(res => this.storageRes = res)
        .catch(e => this.storageRes = e);
    },
    callStorageClear() {
      storage.clear()
        .then(res => this.storageRes = res)
        .catch(e => this.storageRes = e);
    },
    callNfcIsSupported() {
      nfc.isSupported().then(res => this.nfcRes = res).catch(e => this.nfcRes = e)
    },
    callNfcIsEnabled() {
      nfc.isEnabled().then(res => this.nfcRes = res).catch(e => this.nfcRes = e)
    },
    callNfcReadTag() {
      this.nfcIsReading = true
      nfc.readTag().then(res => this.nfcRes = res).catch(e => this.nfcRes = e).finally(()=>this.nfcIsReading = false)
    },
    callNfcStopReading() {
      nfc.stopReading().then(res => this.nfcRes = res).catch(e => this.nfcRes = e).finally(()=>this.nfcIsReading = false)
    },
    callNfcOpenSettings() {
      nfc.openSettings().then(res => this.nfcRes = res).catch(e => this.nfcRes = e)
    },
    callBiometricAccessAuthenticate() {
      this.biometricAccessAuthenticating = true
      biometricAccess.authenticate()
          .then(res => this.biometricAccessAuthenticateResponse = res)
          .catch(e => this.biometricAccessAuthenticateResponse = e)
          .finally(()=>this.biometricAccessAuthenticating = false)
    },
    screenCapture(bol) {
      if (bol) {
        screenCapture.disable()
          .then((res) => this.screenCaptureRes = res)
          .catch(e => this.screenCaptureRes = e);
      } else {
        screenCapture.enable()
          .then((res) => this.screenCaptureRes = res)
          .catch(e => this.screenCaptureRes = e);
      }
    },
    callLogOut() {
      logOut()
        .then((res) => this.logOutRes = res)
        .catch(e => this.logOutRes = e)
    },
    callClose() {
      close()
        .then((res) => this.closeRes = res)
        .catch((e) => this.closeRes = e)
    },
    callHeaderMenuItemsSetItems() {
      headerMenuItems.setItems(this.menuItems)
        .then((res) => this.headerMenuItemsRes = res)
        .catch((e) => this.headerMenuItemsRes = e);
    }
  },
  mounted() {
    if (isWebViewBridgeAvailable()) {
      setTitle('Orta bridge demo');
      headerMenuItems.setClickHandler(this.headerMenuItemClickHandler);
      backArrow.setMode(true)
      backArrow.setVisible(false)
      setTabActiveHandler(this.tabActiveHandler.bind(this))
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.method-container {
  background: lightgray;
  padding: 1rem;
  margin: .5rem .2rem;
  border-radius: .5rem;
}

pre {
  border-radius: .5rem;
  text-align: start;
  overflow: auto;
}

code {
  font-family: monospace;
}

.header-title {
  font-weight: 500;
}

.bool-status {
  font-weight: 600;
  padding: .2rem;
}

.bridge {
  color: white;
  background: lightgreen;
}

.not-bridge {
  color: white;
  background: red;
}

.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width) */
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

h5 {
  margin: .3rem 0;
}

button {
  margin: .5rem;
}
</style>
